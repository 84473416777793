import { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Country, Currency } from '../models';
import Form from './common/Form';
import { Col, Container, Row } from 'react-bootstrap';
import { generate } from 'random-words';
import { LocationClass, Monies } from '../API';

type Props = {
  onChange?: (monies: Monies) => void;
  formData?: Monies;
}

const currentYear = new Date().getUTCFullYear();

const ABOUT_SCHEMA = {
  type: 'object',
  properties: {
    yearOfBirth: {
      title: 'Birth Year',
      type: 'number',
      description: 'We know it\'s rude to ask, but the reality is that age plays a big part in understanding one\'s finances.  Kindly, give it up.',
      maximum: currentYear,
      minimum: currentYear - 120
    },
    bio: {
      title: 'Bio',
      description: 'Tell us a bit about yourself: your financial goals, philosophy on money, or anything you think others should know about your finances',
      type: 'string',
      maxLength: 500,
    },
    name: {
      title: 'Nickname',
      description: 'The moniker by which you will henceforth be known.  Choose wisely.',
      type: 'string',
      maxLength: 25,
    },
    currency: {
      default: Currency.USD,
      enum: Object.values(Currency),
      type: 'string'
    }
  },
  required: ['name', 'yearOfBirth']
};

const JOB_SCHEMA = {
  type: 'object',
  properties: {
    jobTitle: {
      title: 'Job Title',
      description: 'clerk, CEO, astronaut, etc...',
      type: 'string',
      maxLength: 50
    },
    jobIndustry: {
      title: 'Industry',
      type: 'string',
      description: 'big pharma, tech, retail, etc...',
      maxLength: 50
    },
  },
  required: ['jobTitle']
};

const LOCATION_SCHEMA = {
  type: 'object',
  properties: {
    city: {
      title: 'City',
      type: 'string'
    },
    state: {
      title: 'State',
      type: 'string'
    },
    country: {
      title: 'Country',
      type: 'string',
      enum: Object.values(Country),
      default: Country.US
    },
    locationClass: {
      title: 'Very High, High, Medium, Low (COL = Cost of Living)',
      description: 'No need to tell us exactly where you live, but we need to know what the cost of living is like.',
      type: 'string',
      enum: Object.values(LocationClass)
    }
  },
  required: ['locationClass']
};

const COMMON_UI_SCHEMA = {
  "ui:submitButtonOptions": {
    norender: true
  }
}

export default forwardRef(function MoniesCreateForm(props: Props, ref: Ref<any>) {

  const refs = useRef([
    useRef<typeof Form>(),
    useRef<typeof Form>(),
    useRef<typeof Form>()
  ]);

  const [currentFormData, setCurrentFormData] = useState(props.formData);

  useImperativeHandle(ref, () => {
    return {
      validateForm: () => {
        // @ts-ignore
        return refs.current.map(r => r?.current?.validateForm()).every(v => v);
      }
    };
  }, []);

  const onChange = (formData: Monies) => {
    const updatedFormData: Record<string, any> = { ...currentFormData };
    Object.entries(formData).forEach(([k, v]) => {
      updatedFormData[k] = v;
    });
    setCurrentFormData(updatedFormData as Monies);
  };

  useEffect(() => {
    currentFormData && props.onChange?.(currentFormData);
  }, [currentFormData]);

  useEffect(() => {
    setCurrentFormData(props.formData);
  }, [props.formData]);

  return (
    <Container fluid>
      <Row className="py-3">
        <Col md={5}>
          <h1 className="display-5 fw-bold">
            Semi-Personal
          </h1>
          <p className="lead">
            While we would never ever <small className="text-muted">(ever)</small> ask you to identify yourself, we still want to know a little bit about the <i>real</i> you.
          </p>
        </Col>
        <Col>
          <Form
            ref={refs.current[0]}
            formData={{
              ...currentFormData,
              name: currentFormData?.name || generate({ exactly: 1, wordsPerString: 2, separator: "." })[0] }}
            uiSchema={{ 
              'ui:grid': [
                {
                  name: 6
                },
                {
                  bio: 8,
                  yearOfBirth: 4
                }
              ],
              yearOfBirth: {
                  'ui:options': {
                      placeholder: currentYear - 25
                  }
              },
              bio: {
                'ui:widget': 'textarea'
              },
              currency: {
                'ui:widget': 'hidden'
              },
              ...COMMON_UI_SCHEMA
            }}
            // @ts-ignore
            schema={ABOUT_SCHEMA}
            // @ts-ignore
            onChange={({ formData }) => onChange(formData)}
          />
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col md={5}>
            <h1 className="display-5 fw-bold">
            Bread and Butter
            </h1>
            <p className="lead">
              What do you do for a living? If it's too hard to pin it down to one job/industry, 
              just put something <i>cool</i> like <code>Entrepenur/Mankind</code> or <code>Professor/School of Hard Knocks</code> 
              and let us know in the sections below what the hey is actually going on.
            </p>
        </Col>
        <Col>
          <Form
            ref={refs.current[1]}
            formData={currentFormData}
            uiSchema={{ 
              'ui:grid': [{
                jobTitle: 6
              }, {
                jobIndustry: 6
              }],
              ...COMMON_UI_SCHEMA
            }}
            // @ts-ignore
            schema={JOB_SCHEMA}
            // @ts-ignore
            onChange={({ formData }) => onChange(formData)}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={5}>
          <h1 className="display-5 fw-bold">
            Location Location Location
          </h1>
          <p className="lead">
            Where do you live? No need to be too specific, but we all know that having $1M in San Francisco is different than having it in <a href="https://www.kiplinger.com/real-estate/places-to-live/601488/25-cheapest-us-cities-to-live-in">Harlingen, TX</a>
          </p>
        </Col>
        <Col>
          <Form
            ref={refs.current[2]}
            formData={currentFormData}
            uiSchema={{ 
              'ui:grid': [{
                locationClass: 6
              }, {
                city: 5,
                state: 3,
                country: 4
              }],
              ...COMMON_UI_SCHEMA
            }}
            // @ts-ignore
            schema={LOCATION_SCHEMA}
            // @ts-ignore
            onChange={({ formData }) => onChange(formData)}
          />
        </Col>
      </Row>
    </Container>
  );
});
