import { useEffect, useState, useCallback } from 'react';
import { ListPostsQuery, Post } from '../API';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Loader from './common/Loader';

import { gql, useLazyQuery } from '@apollo/client';
import * as queries from '../graphql/queries';
import { Helmet } from 'react-helmet-async';


export default function PostList() {
  const [listPosts, { loading, data }] = useLazyQuery<ListPostsQuery>(gql(queries.listPosts));
  const [posts, setPosts] = useState([]);

  const fetchMore = useCallback(() => {
    listPosts({
      variables: {
        limit: 25,
        nextToken: data?.listPosts?.nextToken
      },
      errorPolicy: 'all'
    })
  }, [data]);

  useEffect(() => {
    const safeOldPosts = posts ? [...posts] : [];
    const safeNewPosts = data?.listPosts?.items ? [...data?.listPosts?.items] : [];
    // @ts-ignore
    setPosts([...safeOldPosts, ...safeNewPosts])
  }, [data]);

  useEffect(() => {
    fetchMore();
  }, []);

  return (
    <>
      <Helmet>
          <title>Discussions | public.finance</title>
          <meta name="description" content="Discussions" />
      </Helmet>
      <Container fluid>
          <h1 className="fw-bold display-1">Discussions</h1>
          {loading && <Loader />}
          {!loading && !posts?.length &&
            <div>
              {/* @ts-ignore */}
              <Button as={Link} to="/post/create" variant="light">Be the first!</Button>
            </div>
          }
          {!loading &&
          <ul className="list-unstyled">
              {posts?.map((post: Post) => post &&
                  <li key={post.id} className="mb-2 hover">
                      <div>
                        <Link to={`/post/${post.id}`} className="text-dark text-decoration-none fw-bold">
                            {post.title}
                        </Link>
                      </div>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Link to={`/monies/${post.owner}`} className="text-dark">
                            <small className="text-muted">{post.author?.name}</small>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <small className="text-muted">{new Date(post.createdAt).toLocaleDateString()}</small>
                        </li>
                      </ul>
                  </li>
              )}
          </ul>
          }
          <Row className="text-center">
            <Col>
            {data?.listPosts?.nextToken && 
              <Button variant="primary" onClick={fetchMore}>More</Button>}
            </Col>
          </Row>
      </Container>
    </>
  );
}
