export const tooltip = {
    callbacks: {
        afterLabel: (context: any) => {
            return context.raw.description
        },
    },
    backgroundColor: '#FFF',
    titleColor: '#000',
    bodyColor: '#000',
    borderColor: 'rgba(0,0,0,.25)',
    borderWidth: 1
}