/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const getMonies = /* GraphQL */ `
  query GetMonies($owner: ID!) {
    getMonies(owner: $owner) {
      name
      assets
      debts
      expenses
      income
      bio
      currency
      city
      state
      country
      locationClass
      yearOfBirth
      jobTitle
      jobIndustry
      owner
      createdAt
      updatedAt
      featured
      _version
    }
  }
`;
export const listMonies = /* GraphQL */ `
  query ListMonies(
    $owner: ID
    $filter: ModelMoniesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMonies(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        assets
        debts
        expenses
        income
        bio
        currency
        city
        state
        country
        locationClass
        yearOfBirth
        jobTitle
        jobIndustry
        createdAt
        updatedAt
        owner
        featured
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const getComment = /* GraphQL */ `
  query GetComment(
    $id: ID!
    $createdAt: AWSDateTime!
  ) {
    getComment(id: $id, createdAt: $createdAt) {
      content
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      moniesID
      postID

      author {
        name
        owner
      }

      replies {
        items {
          id
          content
          createdAt
          moniesID
          postID
          author {
            name
            owner
          }
          replies {
            items {
              id
              content
              createdAt
              moniesID
              postID
              author {
                name
                owner
              }
            }
          }
        }
      }
    }
  }
`;

export const listCommentsByPostId = /* GraphQL */ `
  query ListCommentsByPostId(
    $postID: ID!
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByPostId(postID: $postID, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        content
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        moniesID
        postID

        author {
          name
          owner
        }

        replies {
          items {
            id
            content
            createdAt
            moniesID
            postID
            author {
              name
              owner
            }
            replies {
              items {
                id
                content
                createdAt
                moniesID
                postID
                author {
                  name
                  owner
                }
              }
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listCommentsByMoniesId = /* GraphQL */ `
  query ListCommentsByMoniesId(
    $moniesID: ID!
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByMoniesId(moniesID: $moniesID, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        content
        id
        createdAt
        author {
          name
          owner
        }
        moniesID
        postID
        replies {
          items {
            id
            content
            createdAt
            moniesID
            postID
            author {
              name
              owner
            }

            replies {
              items {
                id
                content
                createdAt
                moniesID
                postID
                author {
                  name
                  owner
                }
              }
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      title
      body
      author {
        name
        owner
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getPostForEdit = /* GraphQL */ `
  query GetPostForEdit($id: ID!) {
    getPost(id: $id) {
      title
      body
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        title
        body
        id
        moniesPostsOwner
        createdAt
        updatedAt
        author {
          name
          owner
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
