/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/
import { CSSProperties, ReactElement, Ref, forwardRef, useEffect, useState } from 'react';
import Form from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import { FormProps } from '@rjsf/core';
import { Row, Col, Button, Container } from "react-bootstrap";
import { ObjectFieldTemplateProps, IconButtonProps, ArrayFieldTemplateProps, StrictRJSFSchema, RJSFSchema, FormContextType, ArrayFieldTemplateItemType, getUiOptions, getTemplate } from '@rjsf/utils';
import SelectDropdownWidget from './SelectDropdownWidget';
import { FormHeaderRowProps } from './FormHeaderRow';
import { XCircle } from 'react-bootstrap-icons';

function RemoveButton(props: IconButtonProps) {
  const { icon, ...btnProps } = props;
  return (
    <Button size="sm" variant="link" {...btnProps} className="text-dark">
      {icon}  <XCircle size={25}/>
    </Button>
  );
}

function AddButton(props: IconButtonProps) {
  const { icon, iconType, ...btnProps } = props;
  return (
    <Button size="sm" variant="link" {...btnProps} className="text-dark">
      {icon} + Add more...
    </Button>
  );
}

const GridLayoutTemplate = (props: ObjectFieldTemplateProps) => {
  return <Row>
    {props.uiSchema?.['ui:grid']?.map((row: Record<string, number>, i: number) => {
      if (Array.isArray(row)) {
        return <Col key={`row-${i}`}><GridLayoutTemplate {...props} uiSchema={{ 'ui:grid': row }} /></Col>
      }
      return <Row key={`row-${i}`}>
        {Object.keys(row).map(fieldName => {
          return <Col key={fieldName} sm={row[fieldName] || "auto"} className="py-2">
            {props.properties?.find(p=>p.name===fieldName)?.content}
          </Col>;
        })}
      </Row>
    })}
  </Row>
};

function ArrayFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateProps<T, S, F>) {
  const { canAdd, disabled, idSchema, uiSchema, items, onAddClick, readonly, registry, required, schema, title } =
    props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const ArrayFieldDescriptionTemplate = getTemplate<'ArrayFieldDescriptionTemplate', T, S, F>(
    'ArrayFieldDescriptionTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldTitleTemplate = getTemplate<'ArrayFieldTitleTemplate', T, S, F>(
    'ArrayFieldTitleTemplate',
    registry,
    uiOptions
  );
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  return (
    <div>
      <Row className='p-0 m-0'>
        <Col className='p-0 m-0'>
          <ArrayFieldTitleTemplate
            idSchema={idSchema}
            title={uiOptions.title || title}
            schema={schema}
            uiSchema={uiSchema}
            required={required}
            registry={registry}
          />
          <ArrayFieldDescriptionTemplate
            idSchema={idSchema}
            description={uiOptions.description || schema.description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
          <Container fluid key={`array-item-list-${idSchema.$id}`} className='p-0 m-0'>
            {items &&
              items.map(({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
                <div className={itemProps.index === itemProps.totalItems - 1 ? "" : "border-bottom"} key={key}>
                  <ArrayFieldItemTemplate 
                    key={key} 
                    {...itemProps}
                    className="p-0" />
                </div>
              ))}
            {canAdd && (
              <Container fluid>
                <Row className='mt-2'>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <AddButton
                      className='array-item-add'
                      onClick={onAddClick}
                      disabled={disabled || readonly}
                      registry={registry}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
        </Col>
      </Row>
    </div>
  );
}

function ArrayFieldItemTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
  >(props: ArrayFieldTemplateItemType<T, S, F>) {
  const {
    children,
    disabled,
    hasToolbar,
    hasCopy,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    index,
    onCopyIndexClick,
    onDropIndexClick,
    onReorderClick,
    readonly,
    registry,
    uiSchema,
  } = props;
  const { CopyButton, MoveDownButton, MoveUpButton, RemoveButton } = registry.templates.ButtonTemplates;
  const btnStyle: CSSProperties = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
  };
  return (
    <div>
      <Row className='d-flex align-items-center'>
        <Col sm='10'>
          {children}
        </Col>
        <Col sm='2'>
          {hasToolbar && (
            <div className='d-flex flex-row'>
              {(hasMoveUp || hasMoveDown) && (
                <div className='m-0 py-2'>
                  <MoveUpButton
                    className='array-item-move-up'
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveUp}
                    onClick={onReorderClick(index, index - 1)}
                    registry={registry}
                  />
                </div>
              )}
              {(hasMoveUp || hasMoveDown) && (
                <div className='m-0 py-2'>
                  <MoveDownButton
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveDown}
                    onClick={onReorderClick(index, index + 1)}
                    registry={registry}
                  />
                </div>
              )}
              {hasCopy && (
                <div className='m-0 py-2'>
                  <CopyButton
                    style={btnStyle}
                    disabled={disabled || readonly}
                    onClick={onCopyIndexClick(index)}
                    registry={registry}
                  />
                </div>
              )}
              {hasRemove && (
                <div className='m-0 py-2'>
                  <RemoveButton
                    style={btnStyle}
                    disabled={disabled || readonly}
                    onClick={onDropIndexClick(index)}
                    registry={registry}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default forwardRef(function(props: Omit<FormProps<any>, 'validator'> & FormHeaderRowProps, ref: Ref<any>) {
  const { formData } = props;

  return (
    <>
        <Row>
          <Col>
            <Form
              ref={ref}
              uiSchema={{
                "ui:ObjectFieldTemplate": props.uiSchema?.['ui:grid'] ? GridLayoutTemplate : undefined,
                "ui:ArrayFieldItemTemplate": ArrayFieldItemTemplate,
                "ui:ArrayFieldTemplate": ArrayFieldTemplate,
                ...props.uiSchema,
                items: {
                  ...props.uiSchema?.items,
                  "ui:ObjectFieldTemplate" : GridLayoutTemplate,
                },
              }}
              schema={props.schema}
              validator={validator}
              onSubmit={props.onSubmit}
              onChange={props.onChange}
              showErrorList={false}
              templates={{ ButtonTemplates: { RemoveButton, AddButton } }}
              formData={formData}
              focusOnFirstError={true}
              widgets={{
                SelectWidget: SelectDropdownWidget,
              }}
            />
          </Col>
        </Row>
    </>
  );
});
