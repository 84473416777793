
import { ListMoniesQuery } from '../../API';
import Loader from './Loader';
import Error from './Error';
import { useQuery, gql } from '@apollo/client';
import * as queries from '../../graphql/queries';
import MoniesCard from './MoniesCard';


export default function FeaturedMonies() {
  const { loading, error, data } = useQuery<ListMoniesQuery>(gql(queries.listMonies), {
    variables: {
        limit: 20,
        filter: {
          featured: {
            attributeExists: true
          }
        }
    }
  });


  return (
    <>
    {error && <Error />}
    {loading && !error && <Loader />}
    {!loading && 
      <>
      <h6 className="fw-bold text-muted">FEATURED</h6>
      <hr/>
      {data?.listMonies?.items?.map(monies => {
        return monies ? <div className="mb-4"><MoniesCard monies={monies} /></div> : null
      })}
      </>
    }
    </>
  );
}
