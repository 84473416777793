import { FormEvent, useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Spinner, InputGroup, Collapse } from 'react-bootstrap';
import { SendFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { useMutation, gql } from '@apollo/client';
import { Comment, CreateCommentMutation } from '../../API';
import { OwnerContext } from '../context/owner';


type Props = {
    comment: Comment
};

export default function CommentComponent({ comment }: Props) {

    const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
    const [replyContent, setReplyContent] = useState<string>();
    const owner = useContext(OwnerContext);

    const [createReply, { loading: saving, client }] = 
        useMutation<CreateCommentMutation>(gql(mutations.createComment));

    const toggleReplyForm = (e: FormEvent) => {
        e.preventDefault();
        setShowReplyForm(!showReplyForm);
    };

    const reply = () => {
        createReply({
            variables: {
                input: {
                    parentCommentID: comment.id,
                    content: replyContent
                }
            },
            refetchQueries: [{ 
                query: gql(queries.getComment), 
                variables: {
                    id: comment.id,
                    createdAt: comment.createdAt
                }
            }]
        })
    };

    return (
        <Container fluid className="p-0">
            <Row>
                <Col>
                    <small className="text-muted text-align-end">
                        <Link 
                            className="text-dark fw-bold text-decoration-none" 
                            to={`/monies/${comment!.author.owner}`}>
                                {comment!.author.name || comment!.owner}
                        </Link>
                        {comment!.createdAt && <span>{' '}{new Date(comment!.createdAt).toLocaleDateString()}</span>}
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    {comment!.content}
                </Col>
            </Row>
            {owner &&
                <Row>
                    <Col>
                        <a onClick={toggleReplyForm} href="#" className="small text-muted text-decoration-none">Reply</a>
                    </Col>
                </Row>
            }
            <Collapse in={showReplyForm} timeout={100}>
                <Row className="py-1">
                    <Col>
                        <InputGroup>
                            <Form.Control 
                                placeholder={`Reply to ${comment.author.name}`}
                                required={true}
                                className="border-dark"
                                onChange={e => setReplyContent(e.target.value)}
                                value={replyContent} />
                            <Button 
                                onClick={reply}
                                variant="primary" 
                                size="sm" 
                                id={"reply" + comment.id}>
                                    {saving ? 
                                <Spinner animation="border" role="status" size="sm" /> : 
                                <SendFill />}
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Collapse>
            {comment.replies?.items && 
                <Row>
                    <Col>
                        <div className="ps-2">
                            {comment.replies?.items.map(c => <CommentComponent comment={c!} />)}
                        </div>
                    </Col>
                </Row>}
        </Container>);
};