import { Ref, forwardRef } from "react";
import { AssetType } from "../models";
import Form from './common/Form';
import { FORM_UI_SCHEMA } from "./constants/forms";
import { Asset } from "./types";

type Props = {
  onSuccess?: (assets: Asset[]) => void;
  formData: Asset[];
}

export default forwardRef(function(props: Props, ref: Ref<any>) {
  
  return <Form
      ref={ref}
      formData={props.formData}
      uiSchema={{
        "ui:submitButtonOptions": {
          norender: true
        },
        items: {
          'ui:grid': [
            {
              value: 3,
              type: undefined,
              description: undefined,
            },
          ],
          ...FORM_UI_SCHEMA
        }
      }}
      schema={{
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            value: {
              type: 'number',
              default: 0
            },
            type: {
              type: 'string',
              enum: Object.values(AssetType)
            },
            description: {
              type: 'string',
              maxLength: 180
            }
          },
          required: ['value', 'type']
        },
      }}
      // @ts-ignore
      onChange={({ formData }) => props.onSuccess?.(formData)}
    />
});
