import { FormEvent, useState, useCallback, useContext } from 'react';
import { ModelCommentConnection, CreateCommentMutation, ListCommentsByPostIdQuery, ListCommentsByMoniesIdQuery } from '../../API';
import { Button, Form, Spinner, ListGroup } from 'react-bootstrap';
import { ChatSquare, SendFill } from 'react-bootstrap-icons';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { useMutation, useQuery, gql } from '@apollo/client';
import Loader from './Loader';
import Comment from './Comment';
import { OwnerContext } from '../context/owner';

type Props = {
    moniesID?: string;
    postID?: string;
};

export default function({ moniesID, postID }: Props) {
  const [comment, setComment] = useState<string>('');
  const owner = useContext(OwnerContext);

  const { loading, refetch, data: listCommentsData } = useQuery<ListCommentsByPostIdQuery | ListCommentsByMoniesIdQuery>(
    gql(moniesID ? 
      queries.listCommentsByMoniesId : queries.listCommentsByPostId), {
    variables: {
        postID,
        moniesID,
        limit: 50
    }
  });

  const [createComment, { loading: saving, client }] = 
    useMutation<CreateCommentMutation>(gql(mutations.createComment));


  const saveComment = useCallback((e: FormEvent) => {
    e.preventDefault();
    // Determine if we're saving against a post or a monies
    const ownerField = postID ? {
      postID
    }: {
      moniesID
    };

    createComment({
      variables: {
        input: {
          content: comment,
          ...ownerField
        }
      },
    }).then(() => {
      setComment('');
      refetch()
    });
    client.clearStore();
  }, [comment, moniesID, postID]);

  // @ts-ignore
  const comments: ModelCommentConnection = listCommentsData?.listCommentsByPostId || listCommentsData?.listCommentsByMoniesId;

  return (
    <div className="">
      {loading && <Loader />}
      <div>
        <Form onSubmit={saveComment} className="mb-4">
              <Form.Control 
                placeholder={postID ? 'Reply to post' : 'Leave a comment'}
                value={comment}
                as="textarea"
                required={true}
                className="mb-3 border-dark"
                onChange={e => setComment(e.target.value)} />
              <div className="text-end">
                <Button variant="primary" size="sm" type="submit">
                    {saving ? 
                      <Spinner animation="border" role="status" size="sm" /> : 
                      (owner ? <SendFill /> : "Login to comment")}
                </Button>
              </div>
        </Form>
      </div>
      {comments?.items.length > 0 && 
        <div>
          <h5 className="fw-bold mb-3"><ChatSquare /> {postID ? 'Replies' : 'Comments'}</h5>
          <ListGroup activeKey={'none'}>
            {comments?.items?.filter(Boolean).map(comment => {
              if (!comment!.content) return null;
              return (
                <ListGroup.Item eventKey={comment!.id} >
                  <Comment comment={comment!} />
              </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      }
    </div>
  );
}
