import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import RecentPosts from "./common/RecentPosts";
import { OwnerContext } from "./context/owner";
import { Post, GetPostQuery, UpdatePostMutation, CreatePostMutation } from '../API';
import Form from "./common/Form";
import { IChangeEvent } from "@rjsf/core";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Loader from "./common/Loader";
import Error from "./common/Error";
import { Helmet } from 'react-helmet-async';

type Props = {

}

export default function(props: Props) {
  const { postId } = useParams();
  const owner = useContext(OwnerContext)?.owner;
  const [authError, setAuthError] = useState<string>();

  const [getPost, { loading, error, data, called }] = 
    useLazyQuery<GetPostQuery>(gql(queries.getPostForEdit));
  const post = data?.getPost;

  const [savePost, savePostResponse] = post ?
     useMutation<UpdatePostMutation>(gql(mutations.updatePost)) : 
     useMutation<CreatePostMutation>(gql(mutations.createPost));

  const navigate = useNavigate();

  useEffect(() => {
    postId && getPost({ variables: { id: postId }});
  }, [postId]);

  const savePostForm = useCallback(({ formData }: IChangeEvent) => {
    const { id, title, body, _version } = formData;
    const postJson = {
      id,
      title,
      body,
      _version,
    } as unknown as Post;

    savePost({ variables: { input: postJson }, onCompleted: (data) => {
      // @ts-ignore
      const postId = data.createPost?.id || data.updatePost?.id;
      navigate(`/post/${postId}`);
    }});
  }, [post, owner]);

  if (loading || savePostResponse.loading) {
    return <Loader />
  }

  const pageTitle = postId ? 'Edit Post' : 'New Post';

  return (<Container fluid>
      <Helmet>
          <title>{ pageTitle } | public.finance</title>
          <meta name="description" content={pageTitle} />
      </Helmet>
      {(error || authError || savePostResponse.error) && <Error message={authError} />}
      <Row>
        <Col md="3">
            <h4 className="fw-bold mb-0">Suggestions</h4>
            <small className="text-muted">(to get the juices flowing)</small>
            <ul>
              <li>Besides a house, what is the biggest purchase you've ever made?</li>
              <li>How do you spend your money after you retire?</li>
              <li>How much money do you give to your kids?</li>
              <li>How do you and your spouse share finances?</li>
            </ul>
        </Col>
        <Col className="mb-3">
            <Form
              onSubmit={savePostForm}
              uiSchema={{
                body: {
                  rows: 5,
                  'ui:widget': 'textarea'
                }
              }}
              formData={post}
              schema={{
                  type: 'object',
                  properties: {
                    title: {
                      title: 'Title',
                      type: 'string'
                    },
                    body: {
                      title: 'Body',
                      type: 'string',
                    }
                  }
              }}
            />
        </Col>
        <Col md="3">
            <RecentPosts />
        </Col>
      </Row>
    </Container>);
}
