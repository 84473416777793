export const schema = {
    "models": {
        "Monies": {
            "name": "Monies",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "assets": {
                    "name": "assets",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "debts": {
                    "name": "debts",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "expenses": {
                    "name": "expenses",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "income": {
                    "name": "income",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "featured": {
                    "name": "featured",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "comments": {
                    "name": "comments",
                    "isArray": true,
                    "type": {
                        "model": "Comment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "moniesCommentsOwner"
                        ]
                    }
                },
                "posts": {
                    "name": "posts",
                    "isArray": true,
                    "type": {
                        "model": "Post"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "moniesPostsOwner"
                        ]
                    }
                },
                "bio": {
                    "name": "bio",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "currency": {
                    "name": "currency",
                    "isArray": false,
                    "type": {
                        "enum": "Currency"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": {
                        "enum": "Country"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "locationClass": {
                    "name": "locationClass",
                    "isArray": false,
                    "type": {
                        "enum": "LocationClass"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "yearOfBirth": {
                    "name": "yearOfBirth",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "jobTitle": {
                    "name": "jobTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "jobIndustry": {
                    "name": "jobIndustry",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Monies",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "owner"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ],
                                "provider": "userPools"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Comment": {
            "name": "Comment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "postID": {
                    "name": "postID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "moniesID": {
                    "name": "moniesID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "replies": {
                    "name": "replies",
                    "isArray": true,
                    "type": {
                        "model": "Comment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "parentCommentID"
                        ]
                    }
                },
                "parentCommentID": {
                    "name": "parentCommentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "author": {
                    "name": "author",
                    "isArray": false,
                    "type": {
                        "model": "Monies"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "owner"
                        ]
                    }
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "moniesCommentsOwner": {
                    "name": "moniesCommentsOwner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "postCommentsId": {
                    "name": "postCommentsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Comments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPostID",
                        "queryField": "listCommentsByPostId",
                        "fields": [
                            "postID",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMoniesID",
                        "queryField": "listCommentsByMoniesId",
                        "fields": [
                            "moniesID",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReply",
                        "fields": [
                            "parentCommentID",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ],
                                "provider": "userPools"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Post": {
            "name": "Post",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "author": {
                    "name": "author",
                    "isArray": false,
                    "type": {
                        "model": "Monies"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "owner"
                        ]
                    }
                },
                "comments": {
                    "name": "comments",
                    "isArray": true,
                    "type": {
                        "model": "Comment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "postCommentsId"
                        ]
                    }
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "moniesPostsOwner": {
                    "name": "moniesPostsOwner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Posts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ],
                                "provider": "userPools"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Country": {
            "name": "Country",
            "values": [
                "US"
            ]
        },
        "DebtType": {
            "name": "DebtType",
            "values": [
                "STUDENT_LOANS",
                "HOME_LOAN",
                "MORTGAGE",
                "HOME_EQUITY_LOAN",
                "AUTO_LOAN",
                "CREDIT_CARD_DEBT",
                "PERSONAL_LOAN",
                "PAYDAY_LOAN",
                "LINE_OF_CREDIT",
                "FEDERAL_TAX_DEBT",
                "MUNICIPAL_BONDS",
                "MEDICAL_DEBT",
                "OTHER"
            ]
        },
        "ExpenseType": {
            "name": "ExpenseType",
            "values": [
                "MORTGAGE",
                "RENT",
                "CHILDCARE",
                "UTILITIES",
                "GROCERIES",
                "TRANSPORTATION",
                "INSURANCE",
                "DEBT_PAYMENTS",
                "SUBSCRIPTIONS",
                "MEMBERSHIPS",
                "DINING_OUT",
                "ENTERTAINMENT",
                "SHOPPING",
                "TRAVEL",
                "HOBBIES",
                "PERSONAL_CARE",
                "GIFTS",
                "CHARITIES",
                "TAXES",
                "MAJOR_PURCHASES",
                "REPAIRS_MAINTENANCE",
                "MEDICAL_BILLS",
                "EDUCATION_COSTS",
                "HOME_IMPROVEMENTS",
                "VACATIONS",
                "PETS",
                "MISC_SPENDING",
                "OTHER"
            ]
        },
        "LocationClass": {
            "name": "LocationClass",
            "values": [
                "LCOL",
                "MCOL",
                "HCOL",
                "VHCOL"
            ]
        },
        "Frequency": {
            "name": "Frequency",
            "values": [
                "MONTHLY",
                "YEARLY",
                "WEEKLY",
                "BIWEEKLY"
            ]
        },
        "IncomeType": {
            "name": "IncomeType",
            "values": [
                "SALARY",
                "BONUS",
                "RSU",
                "SIDE_HUSTLE",
                "ALIMONY",
                "WAGES",
                "COMMISSIONS",
                "BONUSES",
                "FREELANCE_INCOME",
                "BUSINESS_INCOME",
                "RENTAL_INCOME",
                "DIVIDENDS",
                "INTEREST",
                "ROYALTIES",
                "CAPITAL_GAINS",
                "GOVERNMENT_BENEFITS",
                "PENSIONS",
                "ANNUITIES",
                "GIFTS",
                "INHERITANCE",
                "GAMBLING_WINNINGS",
                "PRIZE_MONEY",
                "SETTLEMENTS",
                "TAX_REFUNDS",
                "SIDE_HUSTLE_INCOME",
                "OTHER"
            ]
        },
        "AssetType": {
            "name": "AssetType",
            "values": [
                "STOCK",
                "BONDS",
                "CASH",
                "HOME",
                "REAL_ESTATE",
                "RETIREMENT_401K",
                "RETIREMENT_IRA",
                "CRYPTO",
                "MUTUAL_FUND",
                "ETF",
                "COMMODITY",
                "OPTIONS",
                "FUTURES",
                "FOREIGN_CURRENCY",
                "PRIVATE_EQUITY",
                "VENTURE_CAPITAL",
                "HEDGE_FUND",
                "DERIVATIVES",
                "OTHER"
            ]
        },
        "Currency": {
            "name": "Currency",
            "values": [
                "USD"
            ]
        }
    },
    "nonModels": {},
    "codegenVersion": "3.4.3",
    "version": "798ee5f8389a8b0adb7a06d15b04463e"
};