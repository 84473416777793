import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify, AuthModeStrategyType, Hub, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Outlet, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ReactComponent as Logo } from './ui-components/common/logo.svg';


import UsernameNavLink from './ui-components/common/UsernameNavLink';
import { studioTheme } from './ui-components'

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { useState, useEffect } from "react";
import { initApolloClient } from './utils/backend';

import './App.scss';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure({ 
  ...awsconfig,
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
 });

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    initApolloClient().then(setClient);

    Auth.currentAuthenticatedUser()
          .catch(() => setUser(null))
          .then(user => setUser(user));

    return Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;
      if (event === 'signIn' || event === 'signOut' || event === 'configured') {
        setClient(await initApolloClient());

        Auth.currentAuthenticatedUser()
          .catch(() => setUser(null))
          .then(user => setUser(user));
      }
    });
  }, []);

  if (!client) {
    return <></>;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={studioTheme}>
        <Navbar bg="light" expand="sm" sticky="top" className="border-bottom border-dark px-3">
            <Nav.Link as={Link} to="/" className="px-2 fw-bold fs-4">
              <Logo />
            </Nav.Link>
            <Navbar.Toggle onClick={handleShow} className="border-0" />
            <Navbar.Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="flex-grow-1 pe-3">
                  <>
                    <NavDropdown title="Finances">
                      <NavDropdown.Item as={Link} onClick={handleClose} to="/monies">
                        All Finances
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Community">
                      <NavDropdown.Item as={Link} onClick={handleClose} to="/post">Posts</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} onClick={handleClose} to="/post/create">Create Post</NavDropdown.Item>
                    </NavDropdown>
                  </>
                </Nav>
                <Nav className="align-end pe-3">
                  <UsernameNavLink user={user} onClick={handleClose} />
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
        <div style={{ minHeight: 'calc(100vh - 310px)'}} className="py-3">
          {<Outlet />}
        </div>
        <Navbar bg="primary" className="mt-3 py-3 text-light">
          <Container fluid>
            <Row className="p-5">
              <Col>
                <h5 className="fw-bold">public.finance</h5>
                <p><i>Anonymously</i> share your finances.  Get feedback, learn from others, share your wisdom.</p>
              </Col>
              <Col>
                <h5 className="fw-bold">Connect</h5>
                <p>
                  <Nav.Link as={Link} to="mailto:hello@public.finance">Contact</Nav.Link>
                </p>
              </Col>
              <Col>
              <h5 className="fw-bold">More</h5>
                <p>
                  <Nav.Link as={Link} to="/terms">Terms</Nav.Link>
                </p>
                <p>
                  <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link>
                </p>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
