import { Alert, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type Props = {
    message?: string
}

const DEFAULT_ERROR_MESSAGE = (<>
    <p>Something went wrong and we're working on it! Please try again later.</p>
    <p>If you continue to experience errors please <Nav.Link as={Link} to="mailto:hello@public.finance">contact us</Nav.Link></p>
</>);

export default function({ message }: Props) {
    return (
        <Alert variant="warning" dismissible className="m-3 text-dark">
            <Alert.Heading>Oh no!</Alert.Heading>
            {message ? <p>{message}</p> : DEFAULT_ERROR_MESSAGE}
        </Alert>
    );
};