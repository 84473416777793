import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { Button, Col, Row, Container, Card, Navbar } from 'react-bootstrap';
import MoniesCreateForm from './MoniesCreateForm';
import AssetCreateForm from './AssetCreateForm';
import DebtCreateForm from './DebtCreateForm';
import IncomeCreateForm from './IncomeCreateForm';
import ExpenseCreateForm from './ExpenseCreateForm';
import AssetsChart from './common/AssetsChart';
import IncomeChart from './common/IncomeChart';
import { Asset, Income, Expense, Debt } from './types';
import { Currency } from '../models';
import Form from '@rjsf/core';
import Loader from './common/Loader';
import Error from './common/Error';
import { Helmet } from 'react-helmet-async';

import { OwnerContext } from './context/owner';

import { CreateMoniesMutation, Monies, UpdateMoniesMutation } from '../API';

import * as mutations from '../graphql/mutations';
import { gql, useMutation } from '@apollo/client';

export default function EditMonies() {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [income, setIncome] = useState<Income[]>([]);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [debts, setDebts] = useState<Debt[]>([]);
  const owner = useContext(OwnerContext);

  const [updateMonies, updateMoniesResponse] = 
    useMutation<UpdateMoniesMutation>(gql(mutations.updateMonies));
  const [createMonies, createMoniesResponse] = 
    useMutation<CreateMoniesMutation>(gql(mutations.createMonies));

  const [monies, setMonies] = useState<Monies | undefined>(owner);

  const refs = {
    monies: useRef<Form>(),
    assets: useRef<Form>(),
    debts: useRef<Form>(),
    income: useRef<Form>(),
    expenses: useRef<Form>()
  };

  useEffect(() => {
    if (owner) {
        setAssets(JSON.parse(owner?.assets || "[]"));
        setDebts(JSON.parse(owner?.debts || "[]"));
        setIncome(JSON.parse(owner?.income || "[]"));
        setExpenses(JSON.parse(owner?.expenses || "[]"));
        setMonies(owner);
    }
  }, [owner]);

  useEffect(() => {
    if (updateMoniesResponse.data || createMoniesResponse.data) {
        const newMonies = updateMoniesResponse?.data?.updateMonies
             || createMoniesResponse?.data?.createMonies;
        setMonies(newMonies as Monies);
    }
  }, [createMoniesResponse.data, updateMoniesResponse.data])

  const saveAll = useCallback(() => {
    const allFormsValid = Object.values(refs)
        .map(r => r.current?.validateForm()).every(r => r);

    if (allFormsValid) {
        const moniesToSave = {
            ...monies, 
            assets: JSON.stringify(assets), 
            debts: JSON.stringify(debts), 
            income: JSON.stringify(income), 
            expenses: JSON.stringify(expenses),
            __typename: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            featured: undefined,
        }
        
        owner?.__typename ? updateMonies({ variables: { input: moniesToSave }}) 
            : createMonies({ variables: { input: moniesToSave }});
        
        createMoniesResponse.client.clearStore();
        updateMoniesResponse.client.clearStore();
    }
  }, [assets, debts, income, expenses, owner, monies]);

  const pageTitle = `${monies?.name ?? 'Finances'} | Edit`;
  if (createMoniesResponse.loading || updateMoniesResponse.loading) {
    return <Loader />
  } else {
    return (
        <>
        <Helmet>
          <title>{ pageTitle } | public.finance</title>
          <meta name="description" content={pageTitle} />
        </Helmet>
        {(createMoniesResponse.error || updateMoniesResponse.error) && <Error />}
        <Container fluid className="mb-5">
            <h1 className="display-5 fw-bold">
                Net Worth
            </h1>
            <p className="lead">
                Your total net worth is a difficult thing to calculate, but here we keep it simple.  
                Tell us how much you <i>own</i> and how much you <i>owe</i>, and we'll crunch the numbers for you.
            </p>
            <div className="mb-3">
                <i className="text-muted">Psst...we also know that it's hard to estimate how much something you own is worth, 
                    but give it a shot and let us know in the notes how you got there.</i>
            </div>
            <Row className="py-3">
                <Col lg={3}>
                    <AssetsChart assets={assets} debts={debts} currency={monies?.currency || Currency.USD} />
                </Col>
                <Col>
                    <Card className="mb-3">
                        <Card.Header as="h5">
                            Assets{' '}<small className="text-muted">(value, type, notes)</small>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <AssetCreateForm ref={refs.assets} formData={assets} onSuccess={setAssets} />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header as="h5">
                            Debts{' '}<small className="text-muted">(value, type, notes)</small>
                        </Card.Header>
                        <Card.Subtitle></Card.Subtitle>
                        <Card.Body>
                            <Card.Text>
                                <DebtCreateForm ref={refs.debts} formData={debts} onSuccess={setDebts} />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <hr/>
            <h1 className="display-5 fw-bold">
                Income
            </h1>
            <p className="lead">
                Look, we know you probably make a <strong>ton</strong> of money...but if we're being honest, you probably spend a lot too.
                We want to know all about it! Everything from the annual holiday bonuses to the HBO&trade; subscription you've been meaning to cancel.
            </p>
            <Row>
                <Col lg={3}>
                    <IncomeChart income={income} expenses={expenses} currency={monies?.currency || Currency.USD} />
                </Col>
                <Col>
                    <Card className="mb-3">
                        <Card.Header as="h5">
                            Income{' '}<small className="text-muted">(value, type, frequency, notes)</small>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <IncomeCreateForm ref={refs.income} formData={income} onSuccess={setIncome} />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header as="h5">
                            Expenses{' '}<small className="text-muted">(value, type, frequency, notes)</small>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                            <ExpenseCreateForm ref={refs.expenses} formData={expenses} onSuccess={setExpenses} />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <hr/>
            <Row>
                <MoniesCreateForm ref={refs.monies} formData={monies} onChange={setMonies} />
            </Row>
        </Container>
        <Navbar sticky={'bottom' as 'top'} bg="secondary" className="p-3 m-n3">
            <Col className="d-flex flex-row-reverse">
                <Button variant="primary" size="lg" onClick={saveAll}>Save</Button>
            </Col>
        </Navbar>
    </>);
  }
}
