import { Auth } from "aws-amplify";


import { AUTH_TYPE, AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, NormalizedCacheObject } from "@apollo/client";
import awsconfig from "../aws-exports";
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;

const cognitoAuth = {
  type: 'AMAZON_COGNITO_USER_POOLS' as 'AMAZON_COGNITO_USER_POOLS' | 'OPENID_CONNECT',
  jwtToken: () => Auth.currentSession().then(s => s.getIdToken().getJwtToken())
};

const publicAuth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM as 'AWS_IAM',
    credentials: () => Auth.currentCredentials()
};

export const cache = new InMemoryCache({
    
});

if (window.location.href.includes("localhost")){
    persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });
}

const httpLink = new HttpLink({ uri: url });

let client: ApolloClient<NormalizedCacheObject>;

export const createApolloClient = (auth: AuthOptions) => {
    const link = ApolloLink.from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
    ]);
    return new ApolloClient({
        link,
        cache: cache,
    });
};

export const initApolloClient = () => {
    return Auth.currentAuthenticatedUser()
        .then(user => createApolloClient(cognitoAuth))
        .catch(user => createApolloClient(publicAuth))
};