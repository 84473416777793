export const FORM_UI_SCHEMA = {
    'ui:options': {
      title: false,
    },
    id: {
      'ui:widget': 'hidden'
    },
    type: {
      'ui:options': {
        label: false,
        title:false,
      }
    },
    value: {
      'ui:options': {
        label: false,
      },
    },
    frequency: {
      'ui:options': {
        label: false,
        title:false,
      }
    },
    description: {
      'ui:options': {
        label: false,
        title: false,
        rows: 1
      },
      'ui:placeholder': 'Notes',
      'ui:widget': 'textarea'
    }
}