import { Ref, forwardRef } from "react";
import { DebtType } from "../models";
import Form from './common/Form';
import { FORM_UI_SCHEMA } from "./constants/forms";
import { Debt } from "./types";


type Props = {
  onSuccess?: (income: Debt[]) => void;
  formData: any
}

export default forwardRef(function(props: Props, ref: Ref<any>) {

  return (
    <Form
      ref={ref}
      formData={props.formData}
      uiSchema={{
          "ui:submitButtonOptions": {
            norender: true
          },
          items: {
            'ui:grid': [
              {
                value: 3,
                type: undefined,
                description: undefined
              },
            ],
            ...FORM_UI_SCHEMA
          }
        }}
      schema={{
          type: 'array',
          items: {
            type: 'object',
            properties: {
              value: {
                title: 'Value',
                description: 'the amount of debt you owe',
                type: 'number'
              },
              type: {
                title: 'Type of Debt',
                description: 'the type of debt, such as gambling, loans, IOUs',
                type: 'string',
                enum: Object.values(DebtType)
              },
              description: {
                type: 'string',
              }
            }
          },
          required: ['value', 'type']
        }}
      // @ts-ignore
      onChange={({ formData }) => props.onSuccess?.(formData)}
    />
  );
});
