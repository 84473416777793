import { Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { useNavigate, To } from 'react-router-dom';
import { useEffect } from 'react';

type Props = {
  returnLocation?: To, 
  initialState?: 'signIn' | 'signUp' | 'resetPassword',
  children?: any
}
export default function({ initialState, returnLocation, children }: Props) {

  const navigate = useNavigate();

  useEffect(() => {
    return Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
        if (event === 'signIn' || event === 'signUp') {
          if (!children) {
            returnLocation ? navigate(returnLocation) : navigate(-1)
          }
        } else if (event === 'signOut') {
          navigate('/')
        }
    });
  }, [returnLocation]);

  return (
    <Authenticator initialState={initialState}>
      {() => children}
    </Authenticator>
  );
}