import { useEffect, useState } from 'react';
import { ListPostsQuery, Post } from '../../API';
import { Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Loader from './Loader';
import Error from './Error';

import { useQuery, gql } from '@apollo/client';
import * as queries from '../../graphql/queries';
import { ArrowRight } from 'react-bootstrap-icons';


export default function RecentPosts() {
  const { loading, error, data } = useQuery<ListPostsQuery>(gql(queries.listPosts), {
    variables: {
      limit: 5
    }
  });
  const items = data?.listPosts?.items;

  return (
    <>
        <h6 className="fw-bold">
          <Link to="/post" className="text-decoration-none text-muted">
            POSTS{' '}<ArrowRight />
          </Link>
        </h6>
        <hr />
        {error && <Error />}
        {loading && !error && <Loader />}
        {!loading && !items?.length &&
          <div>
            {/* @ts-ignore */}
            <Button as={Link} to="/post/create" variant="light">Be the first!</Button>
          </div>
        }
        {!loading &&
        <ul className="list-unstyled">
            {items?.map(post => post &&
                <li key={post.id} className="mb-2">
                    <div>
                      <Link to={`/post/${post.id}`} className="text-dark text-decoration-none fw-bold">
                          {post.title}
                      </Link>
                    </div>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <Link to={`/monies/${post.author?.owner}`} className="text-dark">
                          <small className="text-muted">{post.author?.name}</small>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <small className="text-muted">{new Date(post.createdAt).toLocaleDateString()}</small>
                      </li>
                    </ul>
                </li>
            )}
        </ul>
        }
        </>
  );
}
