import { Currency } from '../models';
import { Table, Container, Row, Button, Col } from 'react-bootstrap';
import { getAge, getCurrency, getLocationDisplay, getOccupationDisplay, total, totalWithFrequency } from '../utils';
import { Link } from 'react-router-dom';
import { Asset, Debt, Expense, Income } from './types';

import { ListMoniesQuery, Monies } from '../API';
import Loader from './common/Loader';
import { gql, useLazyQuery } from '@apollo/client';
import * as queries from '../graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';


export default function MoniesList() {
  const [monies, setMonies] = useState([]);
  const [listMonies, { loading, data }] = useLazyQuery<ListMoniesQuery>(gql(queries.listMonies));

  const getNetworth = (monies: Monies) => {
    // @ts-ignore
    const assets: Asset[] = JSON.parse(monies?.assets || "[]");
    // @ts-ignore
    const debts: Debt[] | undefined = JSON.parse(monies?.debts || "[]");
    const currency = getCurrency(total(assets, debts), monies.currency || Currency.USD);

    return <Link className="link-dark" to={`/monies/${monies.owner}`}>{currency}</Link>;
  };

  const getIncome = (monies: Monies) => {
    // @ts-ignore
    const income: Income[] | undefined = JSON.parse(monies?.income || "[]")
    // @ts-ignore
    const expenses: Expense[] | undefined = JSON.parse(monies?.expenses || "[]");
    const incomeTotal = totalWithFrequency(income, expenses);
    const currency = getCurrency(incomeTotal, monies.currency || Currency.USD);

    return <span className={incomeTotal >= 0 ? 'text-primary' : 'text-danger'}>{currency}</span>;
  };

  const fetchMoreCallback = useCallback(() => {
    listMonies({
      variables: {
        limit: 25,
        nextToken: data?.listMonies?.nextToken
      },
      errorPolicy: 'all'
    })
  }, [data]);

  useEffect(() => {
    const safeOldMonies = monies ? [...monies] : [];
    const safeNewMonies = data?.listMonies?.items ? [...data?.listMonies?.items] : [];
    // @ts-ignore
    setMonies([...safeOldMonies, ...safeNewMonies])
  }, [data]);

  useEffect(() => {
    fetchMoreCallback();
  }, []);

  return (
    <>
      <Helmet>
          <title>Finances | public.finance</title>
          <meta name="description" content="Finances" />
      </Helmet>
      <Container fluid>
        <Row>
        <h1 className="display-1 fw-bold">Finances</h1>
        {loading && <Loader />}
        {!loading && 
          <Table hover borderless responsive size="sm">
              <thead>
                  <tr>
                    <th scope="col">NET WORTH</th>
                    <th scope="col">NET INCOME</th>
                    <th scope="col">AGE</th>
                    <th scope="col">OCCUPATION</th>
                    <th scope="col">LOCATION</th>
                  </tr>
              </thead>
              <tbody>
              {monies?.map((monies: Monies) => {
                  return monies ? (
                      <tr key={monies!.owner}>
                          <td>{getNetworth(monies)}</td>
                          <td>{getIncome(monies)}</td>
                          <td>{getAge(monies.yearOfBirth!)}</td>
                          <td>{getOccupationDisplay(monies)}</td>
                          <td>{getLocationDisplay(monies)}</td>
                      </tr>
                  ) : null;
              })}
              </tbody>
          </Table>
        }
        </Row>
      <Row className="text-center">
        <Col>
        {data?.listMonies?.nextToken && 
          <Button variant="primary" onClick={fetchMoreCallback}>More</Button>}
        </Col>
      </Row>
    </Container>
    </>
  );
}
