import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Link
} from 'react-router-dom';
import { Container, Modal, Button, NavDropdown, CloseButton } from 'react-bootstrap';
import { gql, useLazyQuery } from '@apollo/client';

import { GetMoniesQuery } from '../../API';
import * as queries from '../../graphql/queries';
import RequiresAuth from './RequiresAuth';
import { Authenticator } from '@aws-amplify/ui-react';
import { IconClose } from '@aws-amplify/ui-react/dist/types/primitives/Icon';


type Props = {
  user: any | null,
  onClick?: () => void
};

export default function UsernameNavLink({ user, onClick }: Props) {
  const [getMonies, { loading, data }] = 
    useLazyQuery<GetMoniesQuery>(gql(queries.getMonies));
  const [modalState, setModalState] = useState<'signIn' | 'signUp'>();

  useEffect(() => {
    user && getMonies({ variables: { owner: user.username }});
  }, [user]);

  if (loading || user === null) {
    return <></>;
  }

  const name = data?.getMonies?.name;
  return user ? 
    <NavDropdown title={name || user.username}>
        <NavDropdown.Item as={Link} to="/monies/edit" onClick={onClick}>Edit me</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => { onClick?.(); Auth.signOut() }}>
          Sign Out
        </NavDropdown.Item>
    </NavDropdown>
  : <>
      <a href="#" className="me-2 p-2 text-dark" onClick={() => setModalState('signIn')}>Login</a>
      {/* @ts-ignore */}
      <Button as={Link} variant="primary" onClick={() => setModalState('signIn')}>Signup</Button>
      {modalState && 
        <Authenticator 
          initialState={modalState} 
          variation="modal" 
          components={{
            Header() {          
              return (
                <div className="p-3 text-light text-end">
                  <CloseButton variant="white" onClick={() => setModalState(undefined)}/>
                </div>
              );
            },
          }}
      />}
    </>;
}