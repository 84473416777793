import { Monies, Currency } from '../API';
import { BaseEntity, BaseRecurringEntity } from '../ui-components/types';

export enum Frequency {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY"
}

const MINUTE = 60,
  HOUR = MINUTE * 60,
  DAY = HOUR * 24,
  YEAR = DAY * 365;

export const getAge = (year: number) => new Date().getUTCFullYear() - year;

export const getCurrency = (amount: number, currencyCode: string) => {
    const options = { style: 'currency', currency: currencyCode, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat('en-US', options);

    const parts = numberFormat.formatToParts(amount);
    return parts.map(p => p.value);
};

export const getCurrencyString = (amount: number, currencyCode: string = Currency.USD) => {
    const currency = getCurrency(amount, currencyCode);
    return currency.join('');
};

export const getRandomColor = (alpha: string = '0.8') => {
    const num = Math.round(0xaaaaaa * Math.random());
    const r = num >> 16;
    const g = num >> 8 & 255;
    const b = num & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const COLORS = [
  '#F94144',
  '#F3722C',
  '#F8961E',
  '#F9844A',
  '#F9C74F',
  '#90BE6D',
  '#43AA8B',
  '#4D908E',
  '#577590',
  '#277DA1'
];

export const getRandomColors = (numColors: number) => {
  const dist = Math.floor(COLORS.length / numColors);
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const colorIndex = i + dist >= COLORS.length ? 
      COLORS.length - (i+dist) : (i+dist);
    colors.push(COLORS[colorIndex]);
  }
  return colors;
}

export const getMonthlyValue = (value: number, frequency: Frequency | keyof typeof Frequency) => {
    switch (frequency) {
        case Frequency.WEEKLY:
            return value * 4;
        case Frequency.YEARLY:
            return value / 12;
        case Frequency.BIWEEKLY:
            return value * 2;
        default:
            return value;
    }
}

export const getYearlyValue = (value: number, frequency: Frequency | keyof typeof Frequency) => {
    switch (frequency) {
        case Frequency.WEEKLY:
            return value * 52;
        case Frequency.BIWEEKLY:
            return value * 26;
        case Frequency.MONTHLY:
            return value * 12;
        default:
            return value;
    }
}

export const total = (positive: BaseEntity<any>[] | undefined, negative: BaseEntity<any>[] | undefined) => {
    const posTotal = positive?.reduce((sum, entity) => sum + entity.value, 0) || 0;
    const negTotal = negative?.reduce((sum, entity) => sum + entity.value, 0) || 0;
    return posTotal - negTotal;
}

export const totalWithFrequency = (positive: BaseRecurringEntity<any>[] | undefined, negative: BaseRecurringEntity<any>[] | undefined) => {
    const posTotal = positive?.reduce((sum, entity) => sum + getYearlyValue(entity.value, entity.frequency), 0) || 0;
    const negTotal = negative?.reduce((sum, entity) => sum + getYearlyValue(entity.value, entity.frequency), 0) || 0;
    return posTotal - negTotal;
}

export const getLocationDisplay = ({ city, state, country, locationClass }: Monies) => {
  if (city && state) {
    return `${city}, ${state}`;
  } else if (!city && state) {
    return `${state}, ${country}`;
  } else if (!city && !state) {
    return locationClass || 'US';
  }
}

export const getOccupationDisplay = ({ jobIndustry, jobTitle }: Monies) => {
  if (jobTitle && jobIndustry) {
    return `${jobTitle} / ${jobIndustry}`;
  } else if (jobTitle || jobIndustry) {
    return `${jobTitle || jobIndustry}`;
  } else {
    return 'N/A'
  }
}