import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HomePage, Monies, EditMonies, Privacy, Terms, EditPost } from './ui-components';
import RequiresAuth from './ui-components/common/RequiresAuth';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Post from './ui-components/common/Post';
import RequiresMonies from './ui-components/common/RequiresMonies';
import Dashboard from './ui-components/Dashboard';
import MoniesList from './ui-components/MoniesList';
import PostList from './ui-components/PostList';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
        {
          path: "/",
          element: (<Dashboard />),
        },
        {
          path: "/monies/",
          element: (<MoniesList />),
        },
        {
          path: "/monies/:id",
          element: (<RequiresMonies allowMissingMonies={true}><Monies /></RequiresMonies>),
        },
        {
          path: "/monies/edit",
          element: (
            <RequiresAuth initialState="signUp">
              <RequiresMonies allowMissingMonies={true}>
                <EditMonies />
              </RequiresMonies>
            </RequiresAuth>),
        },
        {
          path: "/login",
          element: <RequiresAuth initialState="signIn" returnLocation={'/'} />,
        },
        {
          path: "/signup",
          element: <RequiresAuth initialState="signUp" returnLocation={'/monies/edit'} />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/post",
          element: <PostList />,
        },
        {
          path: "/post/edit/:postId?",
          element: <RequiresAuth initialState="signUp"><RequiresMonies><EditPost /></RequiresMonies></RequiresAuth>,
        },
        {
          path: "/post/create",
          element: <RequiresAuth initialState="signUp"><RequiresMonies><EditPost /></RequiresMonies></RequiresAuth>,
        },
        {
          path: "/post/:postId?",
          element: <RequiresMonies allowMissingMonies={true}><Post /></RequiresMonies>,
        }
    ]
  },
]);

root.render(
  <>
    <HelmetProvider>
      <Helmet>
        {!MediaQueryList.prototype.addEventListener && <script src="https://polyfill.io/v3/polyfill.min.js?features=MediaQueryList.prototype.addEventListener%2CMediaQueryList.prototype.removeEventListener" type='text/javascript'></script>}
      </Helmet>
      <RouterProvider router={router} />
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
