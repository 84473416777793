import { useEffect, useState } from 'react';
import { Currency, Frequency } from '../../models';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getRandomColor, totalWithFrequency, getCurrency, getYearlyValue } from '../../utils';
import { tooltip } from '../constants/charts';
import { Expense, Income } from '../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
    income?: Income[],
    expenses?: Expense[],
    currency: Currency | keyof typeof Currency
};


export default function (props: Props) {
  const [chartData, setChartData] = useState<ChartData<"bar", any[], unknown>>();
  const [netIncome, setNetIncome] = useState<number>(0);

  const CHART_OPTIONS = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      tooltip
    },
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    }
  };

  useEffect(() => {
    setChartData({
       datasets: [{
            label: `Income`,
            backgroundColor: '#004225', // primary
            data: props.income?.map(income => ({
               // description: income.description,
                y: income.type,
                x: getYearlyValue(income.value, income.frequency as Frequency),
            })) || [],
       },
       {
           label: `Expenses`,
           backgroundColor: '#FFB000', // warning
           data: props.expenses?.map(expense => ({
                //description: expense.description,
                y: expense.type,
                x: getYearlyValue(expense.value, expense.frequency as Frequency),
           })) || [],
       }],
     });

     setNetIncome(totalWithFrequency(props.income || [], props.expenses || []));
  }, [props.income, props.expenses]);

  if (chartData) {
    return (
      <>
        <h5 className="fw-bold">Annual Income / Expenses ({props.currency})</h5>
        <div className="text-right">
        <p className="lead">{getCurrency(netIncome, props.currency)}</p>
        {chartData ? <Bar options={CHART_OPTIONS} data={chartData} /> : null}
      </div>
      </>
    );
  }

  return null;
}
