import { Currency } from '../../models';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { 
    getAge, 
    getCurrency, 
    getLocationDisplay, 
    getOccupationDisplay, 
    total, 
    totalWithFrequency,
} from '../../utils';
import { Link } from 'react-router-dom';
import { Asset, Debt, Expense, Income } from '../types';
import { Calendar3, PinMap, Wrench } from 'react-bootstrap-icons';


import { ListMoniesQuery, Monies } from '../../API';
import { useQuery, gql } from '@apollo/client';
import * as queries from '../../graphql/queries';

type Props = {
    monies: Monies
}

export default function MoniesCard({ monies }: Props) {
  const { loading, error, data } = useQuery<ListMoniesQuery>(gql(queries.listMonies));

  const getNetworth = (monies: Monies) => {
    // @ts-ignore
    const assets: Asset[] = JSON.parse(monies?.assets || "[]");
    // @ts-ignore
    const debts: Debt[] | undefined = JSON.parse(monies?.debts || "[]");
    const networthTotal = total(assets, debts);
    const netWorthDisplay = getCurrency(networthTotal, monies.currency || Currency.USD);
    
    return (
        <>
            <span className="fs-5 fw-bold">
                {networthTotal < 0 && "-"}
                {netWorthDisplay}
            </span> net worth
        </>
    );
  };

  const getIncome = (monies: Monies) => {
    // @ts-ignore
    const income: Income[] | undefined = JSON.parse(monies?.income || "[]")
    // @ts-ignore
    const expenses: Expense[] | undefined = JSON.parse(monies?.expenses || "[]");
    const incomeTotal = totalWithFrequency(income, expenses);
    const incomeDisplay = getCurrency(incomeTotal, monies.currency || Currency.USD);

    return  (
        <>
            <span className="fs-5 fw-bold">
                {incomeTotal < 0 && "-"}
                {incomeDisplay}
            </span> / year
        </>
    );
  };

  return (
    <Container fluid className="px-0 mb-3">
        <Row>
            <Col sm={4} className="mb-3">
                <Link to={`/monies/${monies.owner}`} className="text-dark text-decoration-none">
                    <Row>
                        <Col>
                            {getNetworth(monies)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {getIncome(monies)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to={`/monies/${monies.owner}`} className="text-muted">
                                {monies.name}
                            </Link>
                        </Col>
                    </Row>
                </Link>
            </Col>
            <Col>
                <Row>
                    <Col>
                    {monies.bio?.substring(0, 200)} 
                    {(monies.bio?.length || 0) >= 200 && 
                        <Link className="text-dark" to={`/monies/${monies.owner}`}>...</Link>}</Col>
                </Row>
                <Row className="text-muted pt-2">
                    <Col>
                        <Badge bg="warning" text="dark" className="me-2">
                            <Wrench />{' '}
                            {getOccupationDisplay(monies)}
                        </Badge>
                        <Badge className="me-2" bg="warning" text="dark">
                            <PinMap />{' '}
                            {getLocationDisplay(monies)}
                        </Badge>
                        <Badge className="badge" bg="warning" text="dark">
                            <Calendar3 />{' '}
                            {getAge(monies.yearOfBirth)} years old
                        </Badge>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  );
}
