import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAge, getCurrencyString, getLocationDisplay } from '../utils';
import AssetsChart from './common/AssetsChart';
import IncomeChart from './common/IncomeChart';
import { Asset, Debt, Expense, Income } from './types';
import { Table } from 'react-bootstrap';
import Discussion from './common/Discussion';

import { GetMoniesQuery } from '../API';
import Loader from './common/Loader';
import Error from './common/Error';
import { gql, useQuery } from '@apollo/client';

import { Helmet } from 'react-helmet-async';

import * as queries from '../graphql/queries';

export default function () {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [income, setIncome] = useState<Income[]>([]);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [debts, setDebts] = useState<Debt[]>([]);
  const { id } = useParams();

  const { loading, error, data } = 
    useQuery<GetMoniesQuery>(gql(queries.getMonies), { 
      variables: { owner: id },
    });
  const monies = data?.getMonies;

  useEffect(() => {
    monies?.assets && setAssets(JSON.parse(monies?.assets));
    monies?.debts && setDebts(JSON.parse(monies?.debts));
    monies?.income && setIncome(JSON.parse(monies?.income));
    monies?.expenses && setExpenses(JSON.parse(monies?.expenses));
  }, [monies]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }
  if (!monies) {
    return null;
  }
  return (
    <>
    <Helmet>
        <title>{monies?.name} | public.finance</title>
        <meta name="description" content={monies?.name} />
    </Helmet>
    <Container fluid>
      <Row>
        <Col>
          <Row>
              <Col md={4}>
                  <h1 className="fw-bold text-break">
                    {monies.name}
                  </h1>
                  <small className="text-muted">Last updated on {new Date(monies.updatedAt).toLocaleDateString()}</small>
                  <ul className="list-unstyled">
                    <li>
                      <strong>Age: </strong>{getAge(monies.yearOfBirth!)} years old
                    </li> 
                    <li>
                      <strong>Job Title: </strong>{monies.jobTitle}
                    </li>
                    {monies.jobIndustry && 
                    <li>
                      <strong>Industry: </strong>{monies.jobIndustry}
                    </li>}
                    <li>
                      <strong>Location: </strong>{getLocationDisplay(monies)}
                    </li>
                  </ul>
              </Col>
              <Col>
                  <p className="lead">{monies.bio}</p>
              </Col>
          </Row>
          {(income.length > 0 || expenses.length > 0) && <>
            <hr />
            <Row style={{ overflowX: 'auto' }}>
                <Col md={4}>
                  <IncomeChart income={income} expenses={expenses} currency={monies.currency} />
                </Col>
                <Col>
                  <Table hover>
                    <tbody>
                    {income?.map((r, i) => {
                      return (
                        <tr key={r.type + i}>
                          <td className="fw-bold">+{getCurrencyString(r.value, monies.currency)}</td>
                          <td>{r.type}</td>
                          <td>{r.frequency}</td>
                          <td>{r.description}</td>
                        </tr>
                      )
                    })}
                    {expenses?.map((r, i) => {
                      return (
                        <tr key={r.type + i}>
                          <td className="fw-bold">-{getCurrencyString(r.value, monies.currency)}</td>
                          <td>{r.type}</td>
                          <td>{r.frequency}</td>
                          <td>{r.description}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </Table>
                </Col>
            </Row>
          </>}
          {(assets.length > 0 || debts.length > 0) && <>
            <hr />
            <Row style={{ overflowX: 'auto' }}>
                <Col md={4}>
                  <AssetsChart assets={assets} debts={debts} currency={monies.currency} />
                </Col>
                <Col>
                <Table hover>
                  <tbody>
                    {assets?.map((r, i) => {
                      return (
                        <tr key={r.type + i}>
                          <td className="fw-bold">+{getCurrencyString(r.value, monies.currency)}</td>
                          <td>{r.type}</td>
                          <td>{r.description}</td>
                        </tr>
                      )
                    })}
                    {debts?.map((r, i) => {
                      return (
                        <tr key={r.type + i}>
                          <td className="fw-bold">-{getCurrencyString(r.value, monies.currency)}</td>
                          <td>{r.type}</td>
                          <td>{r.description}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </Table>
                </Col>
            </Row>
          </>}
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col>
          <Discussion moniesID={monies.owner} />
        </Col>
      </Row>
    </Container>
    </>
  );
}
