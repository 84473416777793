import { Ref, forwardRef } from "react";
import { IncomeType, Frequency } from "../models";
import Form from './common/Form';
import { FORM_UI_SCHEMA } from "./constants/forms";
import { Income } from "./types";

type Props = {
  onSuccess?: (income: Income[]) => void;
  formData: any;
}

export default forwardRef(function(props: Props, ref: Ref<any>) {

  return (
      <Form
        ref={ref}
        formData={props.formData}
        uiSchema={{
          "ui:submitButtonOptions": {
            norender: true
          },
          items: {
            'ui:grid': [
              {
                value: 3,
                type: undefined,
                frequency: undefined,
                description: undefined
              }
            ],
            ...FORM_UI_SCHEMA
          }
        }}
        schema={{
              type: 'array',
              items: {
              type: 'object',
              properties: {
                value: {
                  type: 'number'
                },
                type: {
                  type: 'string',
                  enum: Object.values(IncomeType)
                },
                frequency: {
                  type: 'string',
                  enum: Object.values(Frequency)
                },
                description: {
                  type: 'string',
                  maxLength: 180
                }
              }
          },
          required: ['value', 'type', 'frequency']
        }}
        // @ts-ignore
        onChange={({ formData }) => props.onSuccess?.(formData)}
      />
  );
});