// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Country = {
  "US": "US"
};

const DebtType = {
  "STUDENT_LOANS": "STUDENT_LOANS",
  "HOME_LOAN": "HOME_LOAN",
  "MORTGAGE": "MORTGAGE",
  "HOME_EQUITY_LOAN": "HOME_EQUITY_LOAN",
  "AUTO_LOAN": "AUTO_LOAN",
  "CREDIT_CARD_DEBT": "CREDIT_CARD_DEBT",
  "PERSONAL_LOAN": "PERSONAL_LOAN",
  "PAYDAY_LOAN": "PAYDAY_LOAN",
  "LINE_OF_CREDIT": "LINE_OF_CREDIT",
  "FEDERAL_TAX_DEBT": "FEDERAL_TAX_DEBT",
  "MUNICIPAL_BONDS": "MUNICIPAL_BONDS",
  "MEDICAL_DEBT": "MEDICAL_DEBT",
  "OTHER": "OTHER"
};

const ExpenseType = {
  "MORTGAGE": "MORTGAGE",
  "RENT": "RENT",
  "CHILDCARE": "CHILDCARE",
  "UTILITIES": "UTILITIES",
  "GROCERIES": "GROCERIES",
  "TRANSPORTATION": "TRANSPORTATION",
  "INSURANCE": "INSURANCE",
  "DEBT_PAYMENTS": "DEBT_PAYMENTS",
  "SUBSCRIPTIONS": "SUBSCRIPTIONS",
  "MEMBERSHIPS": "MEMBERSHIPS",
  "DINING_OUT": "DINING_OUT",
  "ENTERTAINMENT": "ENTERTAINMENT",
  "SHOPPING": "SHOPPING",
  "TRAVEL": "TRAVEL",
  "HOBBIES": "HOBBIES",
  "PERSONAL_CARE": "PERSONAL_CARE",
  "GIFTS": "GIFTS",
  "CHARITIES": "CHARITIES",
  "TAXES": "TAXES",
  "MAJOR_PURCHASES": "MAJOR_PURCHASES",
  "REPAIRS_MAINTENANCE": "REPAIRS_MAINTENANCE",
  "MEDICAL_BILLS": "MEDICAL_BILLS",
  "EDUCATION_COSTS": "EDUCATION_COSTS",
  "HOME_IMPROVEMENTS": "HOME_IMPROVEMENTS",
  "VACATIONS": "VACATIONS",
  "PETS": "PETS",
  "MISC_SPENDING": "MISC_SPENDING",
  "OTHER": "OTHER"
};

const LocationClass = {
  "LCOL": "LCOL",
  "MCOL": "MCOL",
  "HCOL": "HCOL",
  "VHCOL": "VHCOL"
};

const Frequency = {
  "MONTHLY": "MONTHLY",
  "YEARLY": "YEARLY",
  "WEEKLY": "WEEKLY",
  "BIWEEKLY": "BIWEEKLY"
};

const IncomeType = {
  "SALARY": "SALARY",
  "BONUS": "BONUS",
  "RSU": "RSU",
  "SIDE_HUSTLE": "SIDE_HUSTLE",
  "ALIMONY": "ALIMONY",
  "WAGES": "WAGES",
  "COMMISSIONS": "COMMISSIONS",
  "BONUSES": "BONUSES",
  "FREELANCE_INCOME": "FREELANCE_INCOME",
  "BUSINESS_INCOME": "BUSINESS_INCOME",
  "RENTAL_INCOME": "RENTAL_INCOME",
  "DIVIDENDS": "DIVIDENDS",
  "INTEREST": "INTEREST",
  "ROYALTIES": "ROYALTIES",
  "CAPITAL_GAINS": "CAPITAL_GAINS",
  "GOVERNMENT_BENEFITS": "GOVERNMENT_BENEFITS",
  "PENSIONS": "PENSIONS",
  "ANNUITIES": "ANNUITIES",
  "GIFTS": "GIFTS",
  "INHERITANCE": "INHERITANCE",
  "GAMBLING_WINNINGS": "GAMBLING_WINNINGS",
  "PRIZE_MONEY": "PRIZE_MONEY",
  "SETTLEMENTS": "SETTLEMENTS",
  "TAX_REFUNDS": "TAX_REFUNDS",
  "SIDE_HUSTLE_INCOME": "SIDE_HUSTLE_INCOME",
  "OTHER": "OTHER"
};

const AssetType = {
  "STOCK": "STOCK",
  "BONDS": "BONDS",
  "CASH": "CASH",
  "HOME": "HOME",
  "REAL_ESTATE": "REAL_ESTATE",
  "RETIREMENT_401_K": "RETIREMENT_401K",
  "RETIREMENT_IRA": "RETIREMENT_IRA",
  "CRYPTO": "CRYPTO",
  "MUTUAL_FUND": "MUTUAL_FUND",
  "ETF": "ETF",
  "COMMODITY": "COMMODITY",
  "OPTIONS": "OPTIONS",
  "FUTURES": "FUTURES",
  "FOREIGN_CURRENCY": "FOREIGN_CURRENCY",
  "PRIVATE_EQUITY": "PRIVATE_EQUITY",
  "VENTURE_CAPITAL": "VENTURE_CAPITAL",
  "HEDGE_FUND": "HEDGE_FUND",
  "DERIVATIVES": "DERIVATIVES",
  "OTHER": "OTHER"
};

const Currency = {
  "USD": "USD"
};

const { Monies, Comment, Post } = initSchema(schema);

export {
  Monies,
  Comment,
  Post,
  Country,
  DebtType,
  ExpenseType,
  LocationClass,
  Frequency,
  IncomeType,
  AssetType,
  Currency
};