/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateMoniesInput = {
  name: string,
  assets?: string | null,
  debts?: string | null,
  expenses?: string | null,
  income?: string | null,
  featured: number,
  bio?: string | null,
  currency: Currency,
  city?: string | null,
  state?: string | null,
  country: Country,
  locationClass?: LocationClass | null,
  yearOfBirth: number,
  jobTitle?: string | null,
  jobIndustry?: string | null,
  owner: string,
  _version?: number | null,
};

export enum Currency {
  USD = "USD",
}


export enum Country {
  US = "US",
}


export enum LocationClass {
  LCOL = "LCOL",
  MCOL = "MCOL",
  HCOL = "HCOL",
  VHCOL = "VHCOL",
}


export type ModelMoniesConditionInput = {
  name?: ModelStringInput | null,
  assets?: ModelStringInput | null,
  debts?: ModelStringInput | null,
  expenses?: ModelStringInput | null,
  income?: ModelStringInput | null,
  featured?: ModelIntInput | null,
  bio?: ModelStringInput | null,
  currency?: ModelCurrencyInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  locationClass?: ModelLocationClassInput | null,
  yearOfBirth?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  jobIndustry?: ModelStringInput | null,
  and?: Array< ModelMoniesConditionInput | null > | null,
  or?: Array< ModelMoniesConditionInput | null > | null,
  not?: ModelMoniesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCurrencyInput = {
  eq?: Currency | null,
  ne?: Currency | null,
};

export type ModelCountryInput = {
  eq?: Country | null,
  ne?: Country | null,
};

export type ModelLocationClassInput = {
  eq?: LocationClass | null,
  ne?: LocationClass | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Monies = {
  __typename: "Monies",
  name: string,
  assets?: string | null,
  debts?: string | null,
  expenses?: string | null,
  income?: string | null,
  featured: number,
  comments?: ModelCommentConnection | null,
  posts?: ModelPostConnection | null,
  bio?: string | null,
  currency: Currency,
  city?: string | null,
  state?: string | null,
  country: Country,
  locationClass?: LocationClass | null,
  yearOfBirth: number,
  jobTitle?: string | null,
  jobIndustry?: string | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  createdAt: string,
  content: string,
  postID?: string | null,
  moniesID?: string | null,
  replies?: ModelCommentConnection | null,
  parentCommentID?: string | null,
  author: Monies,
  owner?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  moniesCommentsOwner?: string | null,
  postCommentsId?: string | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Post = {
  __typename: "Post",
  title: string,
  body?: string | null,
  author?: Monies | null,
  comments?: ModelCommentConnection | null,
  owner?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  moniesPostsOwner?: string | null,
};

export type UpdateMoniesInput = {
  name?: string | null,
  assets?: string | null,
  debts?: string | null,
  expenses?: string | null,
  income?: string | null,
  featured?: number | null,
  bio?: string | null,
  currency?: Currency | null,
  city?: string | null,
  state?: string | null,
  country?: Country | null,
  locationClass?: LocationClass | null,
  yearOfBirth?: number | null,
  jobTitle?: string | null,
  jobIndustry?: string | null,
  owner: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  createdAt?: string | null,
  content: string,
  postID?: string | null,
  moniesID?: string | null,
  parentCommentID?: string | null,
  owner?: string | null,
  _version?: number | null,
  moniesCommentsOwner?: string | null,
  postCommentsId?: string | null,
};

export type ModelCommentConditionInput = {
  content?: ModelStringInput | null,
  postID?: ModelIDInput | null,
  moniesID?: ModelIDInput | null,
  parentCommentID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  moniesCommentsOwner?: ModelIDInput | null,
  postCommentsId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type CreatePostInput = {
  title: string,
  body?: string | null,
  owner?: string | null,
  id?: string | null,
  _version?: number | null,
  moniesPostsOwner?: string | null,
};

export type ModelPostConditionInput = {
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  moniesPostsOwner?: ModelIDInput | null,
};

export type UpdatePostInput = {
  title?: string | null,
  body?: string | null,
  owner?: string | null,
  id: string,
  _version?: number | null,
  moniesPostsOwner?: string | null,
};

export type ModelMoniesFilterInput = {
  name?: ModelStringInput | null,
  assets?: ModelStringInput | null,
  debts?: ModelStringInput | null,
  expenses?: ModelStringInput | null,
  income?: ModelStringInput | null,
  featured?: ModelIntInput | null,
  bio?: ModelStringInput | null,
  currency?: ModelCurrencyInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  locationClass?: ModelLocationClassInput | null,
  yearOfBirth?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  jobIndustry?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelMoniesFilterInput | null > | null,
  or?: Array< ModelMoniesFilterInput | null > | null,
  not?: ModelMoniesFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMoniesConnection = {
  __typename: "ModelMoniesConnection",
  items:  Array<Monies | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  content?: ModelStringInput | null,
  postID?: ModelIDInput | null,
  moniesID?: ModelIDInput | null,
  parentCommentID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  moniesCommentsOwner?: ModelIDInput | null,
  postCommentsId?: ModelIDInput | null,
};

export type ModelPostFilterInput = {
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  moniesPostsOwner?: ModelIDInput | null,
};

export type CreateMoniesMutationVariables = {
  input: CreateMoniesInput,
  condition?: ModelMoniesConditionInput | null,
};

export type CreateMoniesMutation = {
  createMonies?:  {
    __typename: "Monies",
    name: string,
    assets?: string | null,
    debts?: string | null,
    expenses?: string | null,
    income?: string | null,
    bio?: string | null,
    currency: Currency,
    city?: string | null,
    state?: string | null,
    country: Country,
    locationClass?: LocationClass | null,
    yearOfBirth: number,
    jobTitle?: string | null,
    jobIndustry?: string | null,
    owner: string,
  } | null,
};

export type UpdateMoniesMutationVariables = {
  input: UpdateMoniesInput,
  condition?: ModelMoniesConditionInput | null,
};

export type UpdateMoniesMutation = {
  updateMonies?:  {
    __typename: "Monies",
    name: string,
    assets?: string | null,
    debts?: string | null,
    expenses?: string | null,
    income?: string | null,
    bio?: string | null,
    currency: Currency,
    city?: string | null,
    state?: string | null,
    country: Country,
    locationClass?: LocationClass | null,
    yearOfBirth: number,
    jobTitle?: string | null,
    jobIndustry?: string | null,
    owner: string,
    _version: number,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    content: string,
    author:  {
      __typename: "Monies",
      name: string,
      owner: string,
    },
    id: string,
    createdAt: string,
    owner?: string | null,
    moniesID?: string | null,
    postID?: string | null,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    createdAt: string,
  } | null,
};

export type GetMoniesQueryVariables = {
  owner: string,
};

export type GetMoniesQuery = {
  getMonies?:  {
    __typename: "Monies",
    name: string,
    assets?: string | null,
    debts?: string | null,
    expenses?: string | null,
    income?: string | null,
    bio?: string | null,
    currency: Currency,
    city?: string | null,
    state?: string | null,
    country: Country,
    locationClass?: LocationClass | null,
    yearOfBirth: number,
    jobTitle?: string | null,
    jobIndustry?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    featured: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMoniesQueryVariables = {
  owner?: string | null,
  filter?: ModelMoniesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMoniesQuery = {
  listMonies?:  {
    __typename: "ModelMoniesConnection",
    items:  Array< {
      __typename: "Monies",
      name: string,
      assets?: string | null,
      debts?: string | null,
      expenses?: string | null,
      income?: string | null,
      bio?: string | null,
      currency: Currency,
      city?: string | null,
      state?: string | null,
      country: Country,
      locationClass?: LocationClass | null,
      yearOfBirth: number,
      jobTitle?: string | null,
      jobIndustry?: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      featured: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    content: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    moniesID?: string | null,
    postID?: string | null,
    author:  {
      __typename: "Monies",
      name: string,
      owner: string,
    },
    replies?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        content: string,
        createdAt: string,
        moniesID?: string | null,
        postID?: string | null,
        author:  {
          __typename: "Monies",
          name: string,
          owner: string,
        },
        replies?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            content: string,
            createdAt: string,
            moniesID?: string | null,
            postID?: string | null,
            author:  {
              __typename: "Monies",
              name: string,
              owner: string,
            },
          } | null >,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type ListCommentsByPostIdQueryVariables = {
  postID: string,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByPostIdQuery = {
  listCommentsByPostId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      content: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      moniesID?: string | null,
      postID?: string | null,
      author:  {
        __typename: "Monies",
        name: string,
        owner: string,
      },
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          content: string,
          createdAt: string,
          moniesID?: string | null,
          postID?: string | null,
          author:  {
            __typename: "Monies",
            name: string,
            owner: string,
          },
          replies?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              content: string,
              createdAt: string,
              moniesID?: string | null,
              postID?: string | null,
              author:  {
                __typename: "Monies",
                name: string,
                owner: string,
              },
            } | null >,
          } | null,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCommentsByMoniesIdQueryVariables = {
  moniesID: string,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByMoniesIdQuery = {
  listCommentsByMoniesId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      content: string,
      id: string,
      createdAt: string,
      author:  {
        __typename: "Monies",
        name: string,
        owner: string,
      },
      moniesID?: string | null,
      postID?: string | null,
      replies?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          content: string,
          createdAt: string,
          moniesID?: string | null,
          postID?: string | null,
          author:  {
            __typename: "Monies",
            name: string,
            owner: string,
          },
          replies?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              content: string,
              createdAt: string,
              moniesID?: string | null,
              postID?: string | null,
              author:  {
                __typename: "Monies",
                name: string,
                owner: string,
              },
            } | null >,
          } | null,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    title: string,
    body?: string | null,
    author?:  {
      __typename: "Monies",
      name: string,
      owner: string,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetPostForEditQueryVariables = {
  id: string,
};

export type GetPostForEditQuery = {
  getPost?:  {
    __typename: "Post",
    title: string,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      title: string,
      body?: string | null,
      id: string,
      moniesPostsOwner?: string | null,
      createdAt: string,
      updatedAt: string,
      author?:  {
        __typename: "Monies",
        name: string,
        owner: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};
