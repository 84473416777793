import { Auth, Hub } from 'aws-amplify';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

import { GetMoniesQuery, Monies } from '../../API';
import * as queries from '../../graphql/queries';
import { Container } from 'react-bootstrap';
import { OwnerContext } from '../context/owner';
import Loader from './Loader';
import Error from './Error';

type Props = {
    children?: ReactNode
    allowMissingMonies?: boolean
    fallback?: ReactNode
}

export default function({ children, allowMissingMonies, fallback }: Props) {
  const [getMonies, { error, data }] = 
    useLazyQuery<GetMoniesQuery>(gql(queries.getMonies));
  const [monies, setMonies] = useState<Monies | undefined>(data?.getMonies ?? undefined);
  const [currentUser, setCurrentUser] = useState<any>();
  const [initializing, setInitializing] = useState(true);
  
  const initMonies = useCallback((user: any) => {
    if (!user) {
      setInitializing(false);
      return;
    }

    return getMonies({ variables: { owner: user.username }});
  }, []);

  useEffect(() => {
    // If we have an authenticated user, then init the monies.
    Auth.currentAuthenticatedUser()
      .then(setCurrentUser)
      .catch(() => setInitializing(false));

    return Hub.listen('auth', async (data) => {
      const { payload } = data;
      const { event, data: user } = payload;
      if (event === 'signIn' || event === 'signUp') {
        initMonies(user);
      } else if (event === 'signOut') {
        setMonies(undefined);
      }
    });
  }, []);

  useEffect(() => {
    // If we have an authenticated user, then init the monies.
    if (currentUser) {
      initMonies(currentUser)
    }
  }, [currentUser]);

  useEffect(() => {
    if (data) {
      if (data.getMonies) {
        data?.getMonies && setMonies(data?.getMonies);
      } else {
        allowMissingMonies && setMonies({ owner: currentUser.username } as Monies)
      }
      setInitializing(false);
    }
  }, [data, error, currentUser]);

  if (initializing) {
    return <Loader />;
  }

  if (error) {
    return <Error />
  } 
  
  if (monies || allowMissingMonies) {
    return (
      <OwnerContext.Provider value={monies}>
        {children}
      </OwnerContext.Provider>);
  } else if (!!fallback) {
    return <>{fallback}</>;
  } else {
    return (
      <Container>
        <h1 className="fw-bold display-3">Unlock by adding your finances!</h1>
        <p>Add your finances anonymously and continue exploring all the data.</p>
        <Link to="/monies/edit" className="fw-bold text-primary">Add yours</Link>
      </Container>);
  }
  
}