import { Row, Container, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RecentPosts from './common/RecentPosts';
import { useContext } from 'react';
import { OwnerContext } from "./context/owner";
import FeaturedMonies from './common/FeaturedMonies';

export default function Dashboard() {
  const owner = useContext(OwnerContext)?.owner;

  return (
    <Container fluid>
        <Row className="mb-5">
            <Col lg="7" className="px-4">
                <Row className="mb-3">
                    <h3 className="fw-bold">
                        Anonymously reveal your finances
                    </h3>
                    <p className="lead">Get feedback, learn from others, share your wisdom.
                    <br/>
                    {!owner && 
                        <span>Get started by <Link to="/monies/edit" className="text-dark">adding yours</Link></span>}
                    </p>
                </Row>
                <FeaturedMonies />
            </Col>
            <Col className="px-4">
                <RecentPosts />
            </Col>
        </Row>
    </Container>
  );
}
