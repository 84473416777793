import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import RecentPosts from './RecentPosts';
import Discussion from './Discussion';
import { Helmet } from 'react-helmet-async';
import { GetPostQuery } from '../../API';
import * as queries from '../../graphql/queries';
import Loader from './Loader';
import Error from './Error';

import { gql, useQuery } from '@apollo/client';

type Props = {

};

export default function (props: Props) {
  const { postId } = useParams();
  const { loading, error, data } = 
    useQuery<GetPostQuery>(gql(queries.getPost), { 
      variables: { id: postId }
    });
  const post = data?.getPost;
  const postMonies = data?.getPost?.author;

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <>
      <Helmet>
          <title>{post?.title}</title>
          <meta name="description" content={post?.title} />
      </Helmet>
      <Container fluid>
        <Row>
          <Col className="mb-5">
              <Row>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link className="text-dark" to={`/monies/${postMonies?.owner}`}>{postMonies?.name}</Link>
                  </li>
                  <li className="list-inline-item">
                    {new Date(post?.createdAt!).toLocaleDateString()}
                  </li>
                </ul>
                <h1 className="fw-bold">{post?.title}</h1>
                <p className="lead">{post?.body}</p>
              </Row>
              <hr/>
              <Row>
                  {post && postMonies &&
                    <Discussion postID={post.id} />}
              </Row>
          </Col>
          <Col md="4">
              <RecentPosts />
          </Col>
        </Row>
    </Container>
    </>
  );
}
