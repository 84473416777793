import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { WidgetProps } from '@rjsf/utils';

interface SelectDropdownWidgetProps extends WidgetProps {
  placeholder?: string;
}

const SelectDropdownWidget: React.FC<SelectDropdownWidgetProps> = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  placeholder,
  label
}) => {
  const handleChange = (selectedOption: any) => {
    onChange(selectedOption);
    onBlur(id, selectedOption);
  };

  const handleFocus = () => {
    onFocus(id, value);
  };

  const handleBlur = () => {
    onBlur(id, value);
  };

  const getValue = () => {
    if (schema.type === 'number') {
      return Number(value);
    } else {
      return value;
    }
  };

  const renderOptions = () => {
    return options.enumOptions?.map((option, index) => (
      <Dropdown.Item
        key={index}
        eventKey={option?.value}
        onSelect={handleChange}
      >
        {option.label}
      </Dropdown.Item>
    ));
  };

  return (
    <>
      <Dropdown
        id={id}
        onSelect={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={autofocus}
        className="rjsf-form-dropdown-menu"
      >
        <Dropdown.Toggle
          variant="light"
          disabled={disabled || readonly}
        >
          {getValue() || placeholder || 'Select'}
        </Dropdown.Toggle>

        <Dropdown.Menu>{renderOptions()}</Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SelectDropdownWidget;
