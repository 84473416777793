import { useEffect, useState, forwardRef } from 'react';
import { AssetType, Currency, DebtType } from '../../models';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getCurrencyString, getRandomColor, total } from '../../utils';
import { tooltip } from '../constants/charts';
import { Asset, Debt } from '../types';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
    assets?: Asset[],
    debts?: Debt[],
    currency: Currency | keyof typeof Currency
};

export default function(props: Props) {
  const [chartData, setChartData] = useState<ChartData<"pie", number[], unknown>>();
  const [netWorth, setNetworth] = useState<number>(0);
  
  const sumAssets = (assets: Asset[] | Debt[]): Record<AssetType | DebtType, number> => {
    // @ts-ignore
    return assets.filter(Boolean).reduce((acc, asset) => {
        if (acc[asset.type]) {
            acc[asset.type] += asset.value;
        } else {
            acc[asset.type] = asset.value;
        }
        return acc;
    }, {} as Record<string, number>);
  };

  useEffect(() => {
    const assetSums = sumAssets(props.assets || []);
    const debtSums = sumAssets(props.debts || []);
    const assetSumsValues = Object.values(assetSums);
    const debtSumsValues = Object.values(debtSums);
    const assetColor = '#FFCF9D'; // secondary
    const debtColor = '#B2533E'; // danger

    if (props.assets || props.debts) {
      setChartData({
        labels: [
          ...Object.keys(assetSums).map(s => `Asset: ${s}`), 
          ...Object.keys(debtSums).map(s => `Debt: ${s}`)],
        datasets: [
          {
            data: [...assetSumsValues, ...debtSumsValues],
            backgroundColor: [
              ...assetSumsValues.map(() => assetColor),
              ...debtSumsValues.map(() => debtColor),
            ],
            borderWidth: 2,
            borderColor: '#000'
          },
        ],
      });
    }

    setNetworth(total(props.assets || [], props.debts || []));
  }, [props.assets, props.debts]);

  if (chartData) {
    return (
      <>
        <h5 className="fw-bold">Assets / Debts ({props.currency})</h5>
        <p className="lead">
          {getCurrencyString(netWorth, props.currency)}
        </p>
        <div>
        <Pie data={chartData} options={{
          plugins: {
            tooltip
          }
        }} />
      </div>
      </>
    )
  }

  return null;
}
