/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMonies = /* GraphQL */ `
  mutation CreateMonies(
    $input: CreateMoniesInput!
    $condition: ModelMoniesConditionInput
  ) {
    createMonies(input: $input, condition: $condition) {
      name
      assets
      debts
      expenses
      income
      bio
      currency
      city
      state
      country
      locationClass
      yearOfBirth
      jobTitle
      jobIndustry
      owner
    }
  }
`;
export const updateMonies = /* GraphQL */ `
  mutation UpdateMonies(
    $input: UpdateMoniesInput!
    $condition: ModelMoniesConditionInput
  ) {
    updateMonies(input: $input, condition: $condition) {
      name
      assets
      debts
      expenses
      income
      bio
      currency
      city
      state
      country
      locationClass
      yearOfBirth
      jobTitle
      jobIndustry
      owner
      _version
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      content
      author {
        name
        owner
      }
      id
      createdAt
      owner
      moniesID
      postID
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      createdAt
    }
  }
`;
